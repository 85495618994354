.status-table td{
    text-align: left;
}
.status-section {
    font-weight: bold;
}


.status-icon {
    width: 3rem;
}

.status-details {
    text-align: right;
    font-size: 0.7rem;
    margin-left: auto;

}

.status-line {
    display: flex;
    flex-direction: row;
    border-bottom: 0.1px #80808045 solid;
    align-items: center;
    padding: 0.2rem 0.2rem
}